@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../assets/scss/fonts';

// iframe
// {
//     display: none;
// }

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    'Arial',
    'Battambang',
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

html {
  overflow: hidden;
}

body {
  width: 100%;
  display: flex;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
  background-color: #fff;
}

body #root {
  flex: 1;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaa;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aaa;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aaa;
}

div {
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

button,
button:focus {
  outline: none;
  padding: 0px;
  border: none;
  cursor: pointer;
}

input,
input:focus {
  margin: 0px;
  outline: none;
}

button,
input,
select,
textarea {
  outline: none;
  border-style: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.ball-jackpot {
  border: 1px solid #c80505;
  background: radial-gradient(circle at 65% 15%, #fff 1px, #ff7300 3%, red 60%, #ff7300 100%) !important;
}

.ball-O {
  background: radial-gradient(circle at 65% 15%, white 1px, #ff0000 3%, #c80505 60%, #ff0000 100%);
}

.ball-U {
  background: radial-gradient(circle at 65% 15%, white 1px, aqua 3%, blue 60%, aqua 100%); 
}

.ball-1 {
  background: radial-gradient(circle at 65% 15%, white 1px, #f35656 3%, #a01918 60%, #f35656 100%) !important;
}

.ball-2 { 
  background: radial-gradient(circle at 65% 15%, white 1px, #0077ff 3%, #003f87 60%, #0077ff 100%) !important;
}

.ball-3 {
  background: radial-gradient(circle at 65% 15%, white 1px, #69ff00 3%, #4b9914 60%, #69ff00 100%) !important;
}

.ball-4 {
  background: radial-gradient(circle at 65% 15%, white 1px, #9460d7 3%, #471d75 60%, #9460d7 100%) !important;
}

.ball-5 {
  background: radial-gradient(circle at 65% 15%, white 1px, #ffb747 3%, #c07a0a 60%, #ffb747 100%) !important;
}


.btn-bet {
  background: linear-gradient(to right top, #f72323 30%, red 50%, #c80505) !important;
}

.btn-clear {
 background: linear-gradient(to right top, #3190ff 30%, #0276ff 50%, #0355b5) !important;
}

.btn-cancel {
  background: linear-gradient(to right top, rgb(183, 183, 183) 30%, rgb(162, 162, 162) 50%, rgb(140, 140, 140)) !important;
}

.text-jackpot {
  background-color: transparent;
  color: #feeb53;
  -webkit-filter: drop-shadow(8px 8px 10px rgba(0,0,0,.596));
  filter: drop-shadow(8px 8px 10px rgba(0,0,0,.5960784314));
  font-family: Battambang;
  font-weight: 700;
  text-shadow: 3px 2px #9e6b09;
}

.button-primary {
  background-image: linear-gradient(to right top, rgb(247, 35, 35) 30%, red 50%, rgb(200, 5, 5));
}

.background-red-full {
  background: linear-gradient(to right top, red, rgb(255, 56, 0) 30%, rgb(244, 0, 9) 50%, rgb(200, 4, 4));
}

.background-rainbow {
  -webkit-animation: rainbow 5s ease infinite;
  animation: rainbow 5s ease infinite;
  background: linear-gradient(to bottom right,#1f00ff,red 25%,#1f00ff 50%,red 75%,#1f00ff);
  background-position: 0 0;
  background-size: 100% 400%;
}

.background-rainbow-middle {
  background-color: #F00;
}

.background-rainbow-right {
  background-image: linear-gradient(to top right,#c80505 30%,red 50%,#c80505);
}

.background-rainbow-left {
  background-image: linear-gradient(to bottom right,#c80505 30%,red 50%,#c80505);
}

.box-shadow {
  border: 1px solid #C7D4E7;
  box-shadow: 0px 3px 3px 0px rgba(135, 135, 135, 0.2);
}

@-webkit-keyframes rainbow {
  0%   {background-position: 0 0;}
  50%  {background-position: 0 100%;}
  100% {background-position: 0 0;}
}
@-moz-keyframes rainbow {
  0%   {background-position: 0 0;}
  50%  {background-position: 0 100%;}
  100% {background-position: 0 0;}
}
@keyframes rainbow { 
  0%   {background-position: 0 0;}
  50%  {background-position: 0 100%;}
  100% {background-position: 0 0;}
}

.stick-spin {
  animation: spin 1s linear infinite;
}

.stick-spin-stop {
  animation: spin 1.5s linear 1;
}

@keyframes spin { 
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.spinner {
  animation: spinner 1s linear infinite;
}

@keyframes spinner { 
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slide-up {
  transform: translateY(-101%);
  animation: slideUp 1s linear 1;
}

.slide-down {
  transform: translateY(0px);
  animation: slideDown 0.5s linear 1;
}

@keyframes slideUp {
  from { transform: translateY(0px); }
  to { transform: translateY(-200px); }
}

@keyframes slideDown {
  from { transform: translateY(-200px); }
  to { transform: translateY(0px); }
}

.shake {
  animation: shake .5s 1;
}

@keyframes shake {
  0% {
    -webkit-transform: translate(1px,1px) rotate(0deg);
    transform: translate(1px,1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px,-2px) rotate(-1deg);
    transform: translate(-1px,-2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px) rotate(1deg);
    transform: translate(-3px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(3px,2px) rotate(0deg);
    transform: translate(3px,2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px,-1px) rotate(1deg);
    transform: translate(1px,-1px) rotate(1deg);
  } 
  50% {
    -webkit-transform: translate(-1px,2px) rotate(-1deg);
    transform: translate(-1px,2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px,1px) rotate(0deg);
    transform: translate(-3px,1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(3px,1px) rotate(-1deg);
    transform: translate(3px,1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px,-1px) rotate(1deg);
    transform: translate(-1px,-1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px,2px) rotate(0deg);
    transform: translate(1px,2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px,-2px) rotate(-1deg);
    transform: translate(1px,-2px) rotate(-1deg);
  }
}

.number-inactive {
  background: linear-gradient(to top right,red,#ff3800 30%,#f40009 50%,#c80404);
}

.number-active {
  color: #FFF;
  background: #0000ff;
  animation: numberActive .5s linear 4;
}

@keyframes numberActive {
  0% { background: #0000ff; }
  50% { background: #52aedc; }
  100% { background: #0000ff; }
}

.blink {
  animation: blink 1s linear infinite;
}

@keyframes blink { 
  0% { background: #990000; }
  25% { background: #ebb305; }
  50% { background: #FF5B00; }
  72% { background: #ebb305; }
  100% { background: #990000; }
} 

.reward {
  animation: reward 2s linear infinite;
}

@keyframes reward {
  0% { background: #f000006d; }
  20% { background: #9c27b06d; }
  40% { background: #ffff006d; }
  60% { background: #1f00ff6d; }
  80% { background: #9c27b06d; }
  100% { background: #f000006d; }
}

.scale {
  animation: scale 1s linear infinite;
}

@keyframes scale {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

.ball-odd {
  animation: odd 1s linear 1;
}

.ball-even {
  animation: even 1s linear 1;
}

@keyframes odd {
  0% { transform: translate(-50px, -50px) rotate(-360deg); opacity: 0.6; }
  40% { transform: translate(-30px, -30px); }
  50% { transform: translate(-5px, -25px); }
  60% { transform: translate(-0px, -25px); }
  70% { transform: translate(0px, 0px); }
  80% { transform: translate(0px, 0px); }
  90% { transform: translate(0px, 0px); }
  100% { transform: translate(0px, 0px); }
}
@keyframes even {
  0% { transform: translate(50px, -50px) rotate(360deg); opacity: 0.6; }
  40% { transform: translate(30px, -30px); }
  50% { transform: translate(5px, -25px); }
  60% { transform: translate(0px, -25px); }
  70% { transform: translate(0px, 0px); }
  80% { transform: translate(0px, 0px); }
  90% { transform: translate(0px, 0px); }
  100% { transform: translate(0px, 0px); }
}

.popup {
  animation: popup 1.5s ease 1;
}

@keyframes popup {
  0% { transform: scale(0); }
  100% { transform: scale(1); }
}